import { registerHandler } from 'event-bus';
import { noop } from 'underscore';
import { accountNumber } from 'bv-helpers/session';
import fetchUnreadExists from './api';

const SET_UNREAD = 'userInboxCount/SET_UNREAD';

const setUnread = (status) => ({
  type: SET_UNREAD,
  status,
});

export const subscribe = (dispatch) => {
  registerHandler(`/user/customerinbox/count/${accountNumber()}`, (err, { body: { count } }) => {
    if (count === 0) {
      return dispatch(setUnread(false));
    }
    return dispatch(setUnread(true));
  });
};

export const fetch = () => (dispatch) => {
  fetchUnreadExists()
    .then(({ success, exists }) => {
      if (success) {
        if (exists) {
          dispatch(setUnread(true));
        }
        subscribe(dispatch);
      }
    })
    .catch(noop); // Does nothing if fetch fails
};

const initialState = false;

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_UNREAD:
      return action.status;
    default:
      return state;
  }
};
