import { connect } from 'react-redux';
import { compose } from 'underscore';

import userInboxIsUnread, { fetch } from './duck';
import IconContainer from './icon_container';

const { addReducers } = window.reduxState;

addReducers({
  userInboxIsUnread,
});

const mapStateToProps = (state) => ({
  unread: state.userInboxIsUnread,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: compose(dispatch, fetch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IconContainer);
