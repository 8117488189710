import { useEffect } from 'react';
import PropTypes from 'prop-types';
import IconView from './icon_view';

const IconContainer = ({ fetch, unread }) => {
  useEffect(() => { fetch(); }, []);

  return <IconView unread={unread} />;
};

IconContainer.propTypes = {
  fetch: PropTypes.func.isRequired,
  unread: PropTypes.bool,
};

IconContainer.defaultProps = {
  unread: false,
};

export default IconContainer;
