import PropTypes from 'prop-types';
import { Link, Icon } from 'bv-components';
import classnames from 'classnames';

const IconView = ({ unread }) => (
  <Link to="/customer_inbox" className="customer-inbox">
    <Icon id="inbox" className={classnames('customer-inbox__icon', { unread })} />
  </Link>
);

IconView.propTypes = {
  unread: PropTypes.bool,
};

IconView.defaultProps = {
  unread: false,
};

export default IconView;
