import { Provider } from 'react-redux';
import IconRedux from './icon_redux';

const { store } = window.reduxState;

const IconApp = () => (
  <Provider store={store}>
    <IconRedux />
  </Provider>
);

export default IconApp;

// Note: Usually this index file would only export the component
// The provider should wrap all the application when all migrated to react-redux
